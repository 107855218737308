// App.js

import React from "react";
import "./App.css"; // Import your CSS styles
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import Main from "./Main";
import StatisticsPage from "./components/StatisticsPage";

// Modify the ErrorBoundary component in App.js
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error("Error caught by error boundary:", error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // You can render a fallback UI here
      return (
        <div>
          <h2>Something went wrong.</h2>
          <p>{this.state.error && this.state.error.toString()}</p>
          <p>Component stack trace: {this.state.errorInfo?.componentStack}</p>
        </div>
      );
    }

    return this.props.children;
  }
}

function App() {
  return (
    <Router>
      <div className="App">
        <header>
          <nav>
            <ul>
              <li>
                {" "}
                <Link
                  to="https://nba-university.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Home
                </Link>
              </li>

              <li>
                <Link to="/">Standings</Link>
              </li>
              <li>
                <Link to="/statistics">Statistics</Link>
              </li>
              <li>
                <Link
                  to="https://twitter.com/NBA_University"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span style={{ verticalAlign: "middle" }}></span>
                  <img
                    src="logos/twitter.svg"
                    alt="Instagram"
                    style={{
                      maxHeight: "15px",
                      maxWidth: "15px",
                      display: "inline-block",
                    }}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/nba.university"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span style={{ verticalAlign: "middle" }}></span>
                  <img
                    src="logos/instagram.svg"
                    alt="Instagram"
                    style={{
                      maxHeight: "15px",
                      maxWidth: "15px",
                      display: "inline-block",
                    }}
                  />
                </Link>
              </li>
            </ul>
          </nav>
        </header>

        <img
          src="/logos/nbatournylogo.svg"
          alt="NBA Cup Application"
          className="logo-image"
        />

        <Routes>
          <Route path="/" element={<Main />} />
          {/* Wrap StatisticsPage with ErrorBoundary */}
          <Route
            path="/statistics"
            element={
              <ErrorBoundary>
                <StatisticsPage />
              </ErrorBoundary>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
