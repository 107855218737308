import React, { useState } from "react";
import "./Main.css";
import teams from "./teams";

function Main() {
  const [expandedTeams, setExpandedTeams] = useState([1, 19]);

  const toggleTeam = (teamId) => {
    if (expandedTeams.includes(teamId)) {
      // Team is already expanded, so remove it
      setExpandedTeams(expandedTeams.filter((id) => id !== teamId));
    } else {
      // Team is not expanded, so add it
      setExpandedTeams([...expandedTeams, teamId]);
    }
  };
  const regions = ["West", "East"];
  const groups = ["Group A", "Group B", "Group C"];

  return (
    <div className="main-container">
      {/*<div id="canvas-wrapper">
        <canvas id="main-canvas">
        <NBACup /> Your Three.js component
        </canvas> 
      </div>*/}
      <div className="groups">
        {regions.map((region) => (
          <div className="group-container" key={region}>
            <div className="group-title">{region}</div>
            {groups.map((group) => (
              <div key={group}>
                <div className="subgroup-title">{group}</div>
                <ul className="team-list">
                  {teams
                    .filter((team) => team.group === `${region} ${group}`)
                    .map((team) => (
                      <React.Fragment key={team.id}>
                        <li
                          className={`team-row team${team.id} ${
                            expandedTeams.includes(team.id) ? "expanded" : ""
                          }`}
                          onClick={() => toggleTeam(team.id)}
                        >
                          <div className="team-info">
                            <img
                              src={team.logo}
                              alt={`${team.name} Logo`}
                              className="team-logo"
                            />
                            <div className={`team-details team${team.id}-name`}>
                              {" "}
                              {/*  <span className="team-name">{team.name}</span> */}
                              <div className="team-record">{team.record}</div>
                            </div>
                          </div>
                        </li>
                        {expandedTeams.includes(team.id) && (
                          <li
                            className={`team-row expanded-row team${team.id}`}
                            style={{
                              backgroundImage: `url(${team.backgroundImage})`,
                            }}
                          >
                            <div className="expanded-row">
                              <div
                                className={`info-box box1-${team.id}`}
                                style={{
                                  background: team.backgroundColors.box1,
                                }}
                              >
                                {team.textInfo.box1}
                              </div>
                              <div
                                className={`info-box box2-${team.id}`}
                                style={{
                                  background: team.backgroundColors.box2,
                                }}
                              >
                                {team.textInfo.box2}
                              </div>
                              <div
                                className={`info-box box3-${team.id}`}
                                style={{
                                  background: team.backgroundColors.box3,
                                }}
                              >
                                {team.textInfo.box3}
                              </div>
                              <div
                                className={`info-box box4-${team.id}`}
                                style={{
                                  background: team.backgroundColors.box4,
                                }}
                              >
                                {team.textInfo.box4}
                              </div>
                            </div>
                          </li>
                        )}
                      </React.Fragment>
                    ))}
                </ul>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Main;
