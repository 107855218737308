const teams = [
  {
    id: 1,
    name: "Los Angeles Lakers",
    logo: "/logos/lakers-logo.svg",
    record: "4 - 0 (+74)",
    group: "West Group A",
    backgroundImage: "/courts/lal.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0, 128, 0, 0.9)", // Color for info-box 2
      box3: "rgba(0, 128, 0, 0.8)", // Color for info-box 3
      box4: "rgba(0, 128, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/los-angeles-lakers-phoenix-suns-2023111021/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ PHO
          <br></br>
          122-119
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/memphis-grizzlies-los-angeles-lakers-2023111413/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. MEM
          <br></br>
          123-123
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/memphis-grizzlies-los-angeles-lakers-2023111413/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ POR
          <br></br>
          107-95
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/utah-jazz-los-angeles-lakers-2023112113/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. UTA
          <br></br>
          131-99
        </a>
      ),
    },
  },

   {
    id: 4,
    name: "Phoenix Suns",
    logo: "/logos/suns-logo.svg",
    record: "3 - 1 (+34)",
    group: "West Group A",
    backgroundImage: "/courts/phx.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0, 128, 0, 0.9)", // Color for info-box 2
      box3: "rgba(0, 128, 0, 0.9)", // Color for info-box 3
      box4: "rgba(0, 128, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/los-angeles-lakers-phoenix-suns-2023111021/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. LAL
          <br></br>
          122-119
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/phoenix-suns-utah-jazz-2023111726/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ UTA
          <br></br>
          131-128
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/portland-trail-blazers-phoenix-suns-2023112121/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. POR
          <br></br>
          120-107
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/phoenix-suns-memphis-grizzlies-2023112429/"
          target="_blank"
          rel="noopener noreferrer"
        >
          110-89 @ MEM
          <br></br>
          
        </a>
      ),
    },
  },
    {
    id: 2,
    name: "Utah Jazz",
    logo: "/logos/jazz-logo.svg",
    record: "2 - 2 (-11)",
    group: "West Group A",
    backgroundImage: "/courts/uta.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0, 128, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255, 0, 0, 0.9)", // Color for info-box 3
      box4: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/utah-jazz-memphis-grizzlies-2023111029/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ MEM
          <br></br>
          127-121
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/portland-trail-blazers-utah-jazz-2023111426/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. POR
          <br></br>
          115-99
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/phoenix-suns-utah-jazz-2023111726/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. PHX
          <br></br>
          131-128
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/utah-jazz-los-angeles-lakers-2023112113/"
          target="_blank"
          rel="noopener noreferrer"
        >
          @ LAL
          <br></br>
          NOV. 21
        </a>
      ),
    },
  },
  {
    id: 3,
    name: "Portland Trail Blazers",
    logo: "/logos/trailblazers-logo.svg",
    record: "1 - 3 (-39)",
    group: "West Group A",
    backgroundImage: "/courts/por.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255, 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255, 0, 0, 0.9)", // Color for info-box 3
      box4: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/memphis-grizzlies-portland-trail-blazers-2023110322/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ MEM
          <br></br>
          115-113
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/portland-trail-blazers-utah-jazz-2023111426/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. UTA
          <br></br>
          115-99
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/los-angeles-lakers-portland-trail-blazers-2023111722/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. LAL
          <br></br>
          107-95
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/portland-trail-blazers-phoenix-suns-2023112121/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ PHX
          <br></br>
          120-107
        </a>
      ),
    },
  },
 
  {
    id: 5,
    name: "Memphis Grizzlies",
    logo: "/logos/grizzlies-logo.svg",
    record: "0 - 4 (-56)",
    group: "West Group A",
    backgroundImage: "/courts/mem.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255, 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255, 0, 0, 0.9)", // Color for info-box 3
      box4: "rgba(225, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/memphis-grizzlies-portland-trail-blazers-2023110322/"
          target="_blank"
          rel="noopener noreferrer"
          style={{ background: "red" }}
        >
          L @ POR
          <br></br>
          115-113
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/utah-jazz-memphis-grizzlies-2023111029/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. UTA
          <br></br>
          121-127
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/memphis-grizzlies-los-angeles-lakers-2023111413/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ LAL
          <br></br>
          134-107
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/phoenix-suns-memphis-grizzlies-2023112429/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. PHX
          <br></br>
          110-89
        </a>
      ),
    },
  },
          {
    id: 8,
    name: "New Orleans Pelicans",
    logo: "/logos/pelicans-logo.svg",
    record: "3 - 1 (+33)",
    group: "West Group B",
    backgroundImage: "/courts/nop.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0, 128, 0, 0.9)", // Color for info-box 2
      box3: "rgba(0, 128, 0, 0.9)", // Color for info-box 3

      box4: "rgba(0,128,0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/new-orleans-pelicans-houston-rockets-2023111010/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ HOU
          <br></br>
          104-101
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/dallas-mavericks-new-orleans-pelicans-2023111403/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. DAL
          <br></br>
          131-110
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/denver-nuggets-new-orleans-pelicans-2023111703/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. DEN
          <br></br>
          115-110
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/new-orleans-pelicans-los-angeles-clippers-2023112412/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ LAC
          <br></br>
          116-106
        </a>
      ),
    },
  },

  {
    id: 10,
    name: "Houston Rockets",
    logo: "/logos/rockets-logo.svg",
    record: "2 - 2 (+10)",
    group: "West Group B",
    backgroundImage: "/courts/hou.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255,0,0, 0.9)", // Color for info-box 2
      box3: "rgba(255, 0, 0, 0.9)", // Color for info-box 3

      box4: "rgba(0, 83, 188, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/new-orleans-pelicans-houston-rockets-2023111010/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. NOP
          <br></br>
          104-101
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/houston-rockets-los-angeles-clippers-2023111712/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ LAC
          <br></br>
          106-100
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/denver-nuggets-houston-rockets-2023112410/"
          target="_blank"
          rel="noopener noreferrer"
        >
          vs. DEN
          <br></br>
          105-86
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/houston-rockets-dallas-mavericks-2023112806/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ DAL
          <br></br>
          121-115
        </a>
      ),
    },
  },
  {
    id: 6,
    name: "Denver Nuggets",
    logo: "/logos/nuggets-logo.svg",
    record: "2 - 2 (-10)",
    group: "West Group B",
    backgroundImage: "/courts/den.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0, 128, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
      box4: "rgba(255,0,0, 0.9)", // Color for info-box 3
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/dallas-mavericks-denver-nuggets-2023110307/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. DAL
          <br></br>
          125-114
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/los-angeles-clippers-denver-nuggets-2023111407/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. LAC
          <br></br>
          111-108
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/denver-nuggets-new-orleans-pelicans-2023111703/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ NOP
          <br></br>
          115-110
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/denver-nuggets-houston-rockets-2023112410/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ HOU
          <br></br>
          105-86
        </a>
      ),
    },
  },
  {
    id: 9,
    name: "Dallas Mavericks",
    logo: "/logos/mavericks-logo.svg",
    record: "2 - 2 (-8)",
    group: "West Group B",
    backgroundImage: "/courts/dal.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0, 128, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255, 0, 0, 0.9)", // Color for info-box 3

      box4: "rgba(0,128,0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/dallas-mavericks-denver-nuggets-2023110307/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ DEN
          <br></br>
          125-114
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/los-angeles-clippers-dallas-mavericks-2023111006/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ LAC
          <br></br>
          144-126
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/dallas-mavericks-new-orleans-pelicans-2023111403/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ NOP
          <br></br>
          131-110
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/new-orleans-pelicans-los-angeles-clippers-2023112412/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. HOU
          <br></br>
          121-115
        </a>
      ),
    },
  },

  {
    id: 7,
    name: "LA Clippers",
    logo: "/logos/clippers-logo.svg",
    record: "1 - 3 (-25)",
    group: "West Group B",
    backgroundImage: "/courts/lac.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255, 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(0,128,0, 0.9)", // Color for info-box 3

      box4: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/los-angeles-clippers-dallas-mavericks-2023111006/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ DAL
          <br></br>
          144-126
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/los-angeles-clippers-denver-nuggets-2023111407/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ DEN
          <br></br>
          111-108
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/houston-rockets-los-angeles-clippers-2023111712/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. HOU
          <br></br>
          106-100
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/new-orleans-pelicans-los-angeles-clippers-2023112412/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. NOP
          <br></br>
          116-106
        </a>
      ),
    },
  },
 
  {
    id: 11,
    name: "Sacramento Kings",
    logo: "/logos/kings-logo.svg",
    record: "4 - 0 (+30)",
    group: "West Group C",
    backgroundImage: "/courts/sac.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0, 128, 0, 0.9)", // Color for info-box 2
      box3: "rgba(0, 128, 0, 0.9)", // Color for info-box 3
      box4: "rgba(0, 128, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/oklahoma-city-thunder-sacramento-kings-2023111023/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. OKC
          <br></br>
          105-98
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/sacramento-kings-san-antonio-spurs-2023111724/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ SAS
          <br></br>
          129-120
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/sacramento-kings-minnesota-timberwolves-2023112416/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ MIN
          <br></br>
          124-111
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/golden-state-warriors-sacramento-kings-2023112823/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. GSW
          <br></br>
          124-123
        </a>
      ),
    },
  },
         {
    id: 13,
    name: "Minnesota Timberwolves",
    logo: "/logos/timberwolves-logo.svg",
    record: "3 - 1 (+0)",
    group: "West Group C",
    backgroundImage: "/courts/min.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0, 128, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255,0,0, 0.9)", // Color for info-box 3
      box4: "rgba(0, 128, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/minnesota-timberwolves-san-antonio-spurs-2023111024/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ SAS
          <br></br>
          117-110
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/minnesota-timberwolves-golden-state-warriors-2023111409/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ GSW
          <br></br>
          104-101
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/sacramento-kings-minnesota-timberwolves-2023112416/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. SAC
          <br></br>
          124-111
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/oklahoma-city-thunder-minnesota-timberwolves-2023112816/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. OKC
          <br></br>
          106-203
        </a>
      ),
    },
  },
  {
    id: 12,
    name: "Golden State Warriors",
    logo: "/logos/warriors-logo.svg",
    record: "2 - 2 (+4)",
    group: "West Group C",
    backgroundImage: "/courts/gsw.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255, 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(0, 128, 0, 0.9)", // Color for info-box 3
      box4: "rgba(255,0,0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/golden-state-warriors-oklahoma-city-thunder-2023110325/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ OKC
          <br></br>
          141-139
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/minnesota-timberwolves-golden-state-warriors-2023111409/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. MIN
          <br></br>
          104-101
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/san-antonio-spurs-golden-state-warriors-2023112409/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. SAS
          <br></br>
          118-112
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/golden-state-warriors-sacramento-kings-2023112823/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ SAC
          <br></br>
          124-123
        </a>
      ),
    },
  },

  {
    id: 14,
    name: "Oklahoma City Thunder",
    logo: "/logos/thunder-logo.svg",
    record: "1 - 3 (+24)",
    group: "West Group C",
    backgroundImage: "/courts/okc.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(0, 128, 0, 0.9)", // Color for info-box 3
      box4: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/minnesota-timberwolves-san-antonio-spurs-2023111024/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. GSW
          <br></br>
          117-110
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/oklahoma-city-thunder-sacramento-kings-2023111023/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ SAC
          <br></br>
          105-98
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/san-antonio-spurs-oklahoma-city-thunder-2023111425/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. SAS
          <br></br>
          123-87
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/oklahoma-city-thunder-minnesota-timberwolves-2023112816/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ MIN
          <br></br>
          106-103
        </a>
      ),
    },
  },
          {
    id: 15,
    name: "San Antonio Spurs",
    logo: "/logos/spurs-logo.svg",
    record: "0 - 4 (-58)",
    group: "West Group C",
    backgroundImage: "/courts/sas.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255,0,0, 0.9)", // Color for info-box 3
      box4: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/minnesota-timberwolves-san-antonio-spurs-2023111024/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. MIN
          <br></br>
          117-110
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/san-antonio-spurs-oklahoma-city-thunder-2023111425/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ OKC
          <br></br>
          123-107
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/sacramento-kings-san-antonio-spurs-2023111724/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. SAC
          <br></br>
          129-120
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/san-antonio-spurs-golden-state-warriors-2023112409/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ GSW
          <br></br>
          118-112
        </a>
      ),
    },
  },
  {
    id: 19,
    name: "Indiana Pacers",
    logo: "/logos/pacers-logo.svg",
    record: "4 - 0 (+39)",
    group: "East Group A",
    isGlowing: true,
    backgroundImage: "/courts/ind.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0, 128, 0, 0.9)", // Color for info-box 2
      box3: "rgba(0, 128, 0, 0.9)", // Color for info-box 3
      box4: "rgba(0,128,0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/cleveland-cavaliers-indiana-pacers-2023110311/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. CLE
          <br></br>
          121-116
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/indiana-pacers-philadelphia-76ers-2023111420/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ PHI
          <br></br>
          132-126
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/indiana-pacers-atlanta-hawks-2023112101/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ ATL
          <br></br>
          157-152
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/detroit-pistons-indiana-pacers-2023112411/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. DET
          <br></br>
          136-113
        </a>
      ),
    },
  },
         
  {
    id: 17,
    name: "Cleveland Cavaliers",
    logo: "/logos/cavaliers-logo.svg",
    record: "3 - 1 (+22)",
    group: "East Group A",
    backgroundImage: "/courts/cle.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0,128,0, 0.9)", // Color for info-box 2
      box3: "rgba(0, 128, 0, 0.9)", // Color for info-box 3
      box4: "rgba(0, 128, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/cleveland-cavaliers-indiana-pacers-2023110311/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ IND
          <br></br>
          121-116
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/indiana-pacers-philadelphia-76ers-2023111420/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. DET
          <br></br>
          108-100
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/cleveland-cavaliers-philadelphia-76ers-2023112120/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ PHI
          <br></br>
          122-119
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/atlanta-hawks-cleveland-cavaliers-2023112805/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. ATL
          <br></br>
          128-105
        </a>
      ),
    },
  },
         {
    id: 16,
    name: "Philadelphia 76ers",
    logo: "/logos/sixers-logo.svg",
    record: "2 - 2 (+9)",
    group: "East Group A",
    backgroundImage: "/courts/phi.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(0, 128, 0, 0.9)", // Color for info-box 3
      box4: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/philadelphia-76ers-detroit-pistons-2023111008/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ DET
          <br></br>
          114-106
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/indiana-pacers-philadelphia-76ers-2023111420/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. IND
          <br></br>
          132-126
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/philadelphia-76ers-atlanta-hawks-2023111701/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ ATL
          <br></br>
          126-116
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/cleveland-cavaliers-philadelphia-76ers-2023112120/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. CLE
          <br></br>
          122-119
        </a>
      ),
    },
  },
  {
    id: 18,
    name: "Atlanta Hawks",
    logo: "/logos/hawks-logo.svg",
    record: "1 - 2 (-25)",
    group: "East Group A",
    backgroundImage: "/courts/atl.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255, 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255, 0, 0, 0.9)", // Color for info-box 3
      box4: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/atlanta-hawks-detroit-pistons-2023111408/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ DET
          <br></br>
          126-120
        </a>
      ),
      box2: (
        <a
          href="hhttps://sports.yahoo.com/nba/philadelphia-76ers-atlanta-hawks-2023111701/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. PHI
          <br></br>
          126-116
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/indiana-pacers-atlanta-hawks-2023112101/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. IND
          <br></br>
          157-152
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/atlanta-hawks-cleveland-cavaliers-2023112805/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ CLE
          <br></br>
          128-105
        </a>
      ),
    },
  },


  {
    id: 20,
    name: "Detroit Pistons",
    logo: "/logos/pistons-logo.svg",
    record: "0 - 4 (-45)",
    group: "East Group A",
    backgroundImage: "/courts/det.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255, 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255, 0, 0, 0.9)", // Color for info-box 3
      box4: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/philadelphia-76ers-detroit-pistons-2023111008/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. PHI
          <br></br>
          114-106
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/atlanta-hawks-detroit-pistons-2023111408/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. ATL
          <br></br>
          126-120
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/detroit-pistons-cleveland-cavaliers-2023111705/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ CLE
          <br></br>
          108-100
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/detroit-pistons-indiana-pacers-2023112411/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ IND
          <br></br>
          136-113
        </a>
      ),
    },
  },
  
  {
    id: 21,
    name: "Milwaukee Bucks",
    logo: "/logos/bucks-logo.svg",
    record: "4 - 0 (+46)",
    group: "East Group B",
    backgroundImage: "/courts/mil.avif",
    backgroundColors: {
      box1: "rgba(0,128,0, 0.9)", // Color for info-box 1
      box2: "rgba(0, 128, 0, 0.9)", // Color for info-box 2
      box3: "rgba(0,128,0, 0.9)", // Color for info-box 3
      box4: "rgba(0, 128, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/new-york-knicks-milwaukee-bucks-2023110315/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. NYK
          <br></br>
          110-105
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/milwaukee-bucks-charlotte-hornets-2023111730/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ CHA
          <br></br>
          130-99
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/milwaukee-bucks-washington-wizards-2023112027/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. WAS
          <br></br>
          131-128
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/milwaukee-bucks-miami-heat-2023112814/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ MIA
          <br></br>
          131-124
        </a>
      ),
    },
  },
                  {
    id: 22,
    name: "New York Knicks",
    logo: "/logos/knicks-logo.svg",
    record: "3 - 1 (+42)",
    group: "East Group B",
    backgroundImage: "/courts/nyk.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0,128,0, 0.9)", // Color for info-box 2
      box3: "rgba(0, 128, 0, 0.9)", // Color for info-box 3
      box4: "rgba(0, 128, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/new-york-knicks-milwaukee-bucks-2023110315/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ MIL
          <br></br>
          110-105
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/new-york-knicks-washington-wizards-2023111727/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ WAS
          <br></br>
          120-99
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/miami-heat-new-york-knicks-2023112418/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. MIA
          <br></br>
          100-98
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/charlotte-hornets-new-york-knicks-2023112818/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. CHA
          <br></br>
          115-91
        </a>
      ),
    },
  },

        {
    id: 23,
    name: "Miami Heat",
    logo: "/logos/heat-logo.svg",
    record: "2 - 2 (+4)",
    group: "East Group B",
    backgroundImage: "/courts/mia.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0, 128, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255, 0, 0, 0.9)", // Color for info-box 3
      box4: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/washington-wizards-miami-heat-2023110314/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. WAS
          <br></br>
          121-114
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/miami-heat-charlotte-hornets-2023111430/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ CHA
          <br></br>
          111-105
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/miami-heat-new-york-knicks-2023112418/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ NYK
          <br></br>
          100-98
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/milwaukee-bucks-miami-heat-2023112814/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. MIL
          <br></br>
          131-124
        </a>
      ),
    },
  },

  {
    id: 25,
    name: "Charlotte Hornets",
    logo: "/logos/hornets-logo.svg",
    record: "1 - 3 (-54)",
    group: "East Group B",
    backgroundImage: "/courts/cha.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255, 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255, 0, 0, 0.9)", // Color for info-box 3
      box4: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/charlotte-hornets-washington-wizards-2023111027/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. WAS
          <br></br>
          124-117
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/miami-heat-charlotte-hornets-2023111430/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ MIA
          <br></br>
          111-105
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/milwaukee-bucks-charlotte-hornets-2023111730/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. MIL
          <br></br>
          130-99
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/charlotte-hornets-new-york-knicks-2023112818/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ NYK
          <br></br>
          115-91
        </a>
      ),
    },
  },

  {
    id: 24,
    name: "Washington Wizards",
    logo: "/logos/wizards-logo.svg",
    record: "0 - 4 (-38)",
    group: "East Group B",
    backgroundImage: "/courts/was.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255, 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255, 0, 0, 0.9)", // Color for info-box 3
      box4: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/washington-wizards-miami-heat-2023110314/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. MIA
          <br></br>
          121-114
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/charlotte-hornets-washington-wizards-2023111027/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. CHA
          <br></br>
          124-117
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/new-york-knicks-washington-wizards-2023111727/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. NYK
          <br></br>
          120-99
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/washington-wizards-milwaukee-bucks-2023112415/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ MIL
          <br></br>
          131-128
        </a>
      ),
    },
  },
        {
    id: 26,
    name: "Boston Celtics",
    logo: "/logos/celtics-logo.svg",
    record: "3 - 1 (+27)",
    group: "East Group C",
    backgroundImage: "/courts/bos.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0, 128, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255, 0, 0, 0.9)", // Color for info-box 3
      box4: "rgba(0, 128, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/brooklyn-nets-boston-celtics-2023111002/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. BRK
          <br></br>
          121-107
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/boston-celtics-toronto-raptors-2023111728/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ TOR
          <br></br>
          108-105
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/boston-celtics-orlando-magic-2023112419/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ ORL
          <br></br>
          113-96
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/chicago-bulls-boston-celtics-2023112802/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. CHI
          <br></br>
          124-97
        </a>
      ),
    },
  },
       {
    id: 30,
    name: "Orlando Magic",
    logo: "/logos/magic-logo.svg",
    record: "3 - 1 (+23)",
    group: "East Group C",
    backgroundImage: "/courts/orl.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(0, 128, 0, 0.9)", // Color for info-box 2
      box3: "rgba(0, 128, 0, 0.9)", // Color for info-box 3
      box4: "rgba(0, 122, 51, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/orlando-magic-brooklyn-nets-2023111417/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ BRK
          <br></br>
          124-104
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/orlando-magic-chicago-bulls-2023111704/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ CHI
          <br></br>
          103-97
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/toronto-raptors-orlando-magic-2023112119/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. TOR
          <br></br>
          126-107
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/boston-celtics-orlando-magic-2023112419/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. BOS
          <br></br>
         113-96
        </a>
      ),
    },
  },

  {
    id: 27,
    name: "Brooklyn Nets",
    logo: "/logos/nets-logo.svg",
    record: "3 - 1 (+20)",
    group: "East Group C",
    backgroundImage: "/courts/bkn.avif",
    backgroundColors: {
      box1: "rgba(0, 128, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255, 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(0, 128, 0, 0.9)", // Color for info-box 3
      box4: "rgba(0, 128, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/brooklyn-nets-chicago-bulls-2023110304/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W @ CHI
          <br></br>
          109-107
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/brooklyn-nets-boston-celtics-2023111002/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ BOS
          <br></br>
          121-107
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/orlando-magic-brooklyn-nets-2023111417/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. ORL
          <br></br>
          124-104
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/toronto-raptors-brooklyn-nets-2023112817/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. TOR
          <br></br>
          115-103
        </a>
      ),
    },
  },
  

          {
    id: 28,
    name: "Toronto Raptors",
    logo: "/logos/raptors-logo.svg",
    record: "1 - 3 (-21)",
    group: "East Group C",
    backgroundImage: "/courts/tor.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255, 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(0, 128, 0, 0.9)", // Color for info-box 3
      box4: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/boston-celtics-toronto-raptors-2023111728/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. BOS
          <br></br>
          108-105
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/toronto-raptors-orlando-magic-2023112119/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ ORL
          <br></br>
          126-107
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/chicago-bulls-toronto-raptors-2023112428/"
          target="_blank"
          rel="noopener noreferrer"
        >
          W vs. CHI
          <br></br>
          121-108
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/toronto-raptors-brooklyn-nets-2023112817/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ BKN
          <br></br>
          115-103
        </a>
      ),
    },
  },
   {
    id: 29,
    name: "Chicago Bulls",
    logo: "/logos/bulls-logo.svg",
    record: "0 - 4 (-48)",
    group: "East Group C",
    backgroundImage: "/courts/chi.avif",
    backgroundColors: {
      box1: "rgba(255, 0, 0, 0.9)", // Color for info-box 1
      box2: "rgba(255, 0, 0, 0.9)", // Color for info-box 2
      box3: "rgba(255, 0, 0, 0.9)", // Color for info-box 3
      box4: "rgba(255, 0, 0, 0.9)", // Color for info-box 4
    },
    textInfo: {
      box1: (
        <a
          href="https://sports.yahoo.com/nba/brooklyn-nets-chicago-bulls-2023110304/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L vs. BRK
          <br></br>
          109-107
        </a>
      ),
      box2: (
        <a
          href="https://sports.yahoo.com/nba/orlando-magic-chicago-bulls-2023111704/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ ORL
          <br></br>
          103-97
        </a>
      ),
      box3: (
        <a
          href="https://sports.yahoo.com/nba/chicago-bulls-toronto-raptors-2023112428/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ TOR
          <br></br>
          121-108
        </a>
      ),
      box4: (
        <a
          href="https://sports.yahoo.com/nba/chicago-bulls-boston-celtics-2023112802/"
          target="_blank"
          rel="noopener noreferrer"
        >
          L @ BOS
          <br></br>
          124-97
        </a>
      ),
    },
  },
];

export default teams;
