import React, { useEffect, useState, useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import "./StatisticsPage.css";
import { useTheme } from "@mui/material";

const StatisticsPage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    // Fetch CSV data
    fetch(
      "https://raw.githubusercontent.com/gabriel1200/site_Data/main/inseason_2024.csv"
    )
      .then((response) => response.text())
      .then((csvData) => {
        const rows = csvData.split("\n");
        const csvHeaders = rows[0].split(",");
        const problematicRow = rows[281]; // 282nd row (arrays are zero-indexed)

        // Log the values of the problematic row
        console.log("Problematic Row Values:", problematicRow);
        // Convert CSV data to an array of objects
        const problematicRowIndex = 282;

        // ...

        const dataArray = rows.slice(1).map((row, index) => {
          const values = row.split(",");
          const rowData = { id: index + 1 }; // Add unique ID for each row

          csvHeaders.forEach((header, columnIndex) => {
            // Trim whitespaces from header names
            const trimmedHeader = header.trim();

            // Parse numeric columns as numbers
            let parsedValue = isNaN(values[columnIndex])
              ? values[columnIndex]
              : parseFloat(values[columnIndex]);

            // Format GP, W, L without .0
            if (["GP", "W", "L"].includes(trimmedHeader)) {
              parsedValue = parseInt(parsedValue, 10);
            } else {
              // Ensure all other numeric values have ".0" or "0." attached
              parsedValue = Number.isFinite(parsedValue)
                ? parseFloat(parsedValue).toFixed(1)
                : parsedValue;
            }

            rowData[trimmedHeader] = parsedValue;
          });

          return rowData;
        });

        // Remove the problematic row
        const filteredDataArray = dataArray.filter(
          (_, index) => index !== problematicRowIndex - 1
        );

        setData(filteredDataArray);
      })
      .catch((error) => console.error("Error fetching CSV:", error));
  }, []);

  const columns = useMemo(() => {
    if (data.length === 0) return [];

    return Object.keys(data[0])
      .filter((key) => !["Age", "FP", "DD2", "TD3"].includes(key))
      .map((key) => ({
        accessorKey: key,
        header: key,
        sortable: key === "Player" || key === "Team" ? false : true,
        filterable: key === "Player" || key === "Team" ? false : true,
        sortComparator:
          key !== "GP" && key !== "W" && key !== "L"
            ? (v1, v2) => parseFloat(v1) - parseFloat(v2)
            : undefined,
      }));
  }, [data]);

  const theme = useTheme(); //(optional) if you already have a theme defined in your app root, you can import here

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 20, //customize the default page size
  });

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnPinning: true,
    enablePinning: true,
    displayColumnDefOptions: {
      "mrt-row-numbers": {
        enablePinning: true,
        enableSorting: true,
        enableColumnOrdering: true,
      },
    },
    muiTableHeadCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example
      sx: {
        fontSize: "12px",
        fontWeight: "bold",
        m: 1,
        pb: 0.3,
        px: 0.3,
        py: 0.5,
      },
    },
    muiTableBodyCellProps: {
      //simple styling with the `sx` prop, works just like a style prop in this example
      sx: {
        fontWeight: "normal",
        fontSize: "12px",
        m: 0.3,
        px: 0.3,
        py: 0.5,
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.grey[900]
            : theme.palette.grey[50],
      },
    },

    initialState: {
      density: "compact",
      columnVisibility: { id: false, TD3: false, DD2: false, FP: false },
      columnPinning: { left: ["mrt-row-numbers", "Player"] },
    },
    defaultColumn: {
      minSize: 5, //allow columns to get smaller than default
      maxSize: 15,
      maxHeight: 10, //allow columns to get larger than default
      size: 15, //make columns wider by default
    },
    enableColumnResizing: false,
    columnResizeMode: "onChange",
    layoutMode: "semantic",
    enableColumnActions: false,
    enableRowNumbers: true,
    rowNumberDisplayMode: "static",
    sortDescFirst: true,

    onPaginationChange: setPagination, //hoist pagination state to your state when it changes internally
    state: { pagination },
  });

  return (
    <div className="statistics-page-container">
      <div className="table-container">
        <MaterialReactTable table={table} />
      </div>
    </div>
  );
};

export default StatisticsPage;
